import React from "react"
import { SimpleBannerStyles } from "./SimpleBannerStyles"

const SimpleBanner = ({ children, title }) => {
    return (
        <SimpleBannerStyles>
            {children}

            {title && (
                <div className="container">
                    <div className="banner__content">
                        <h1>
                            {title}
                            <span style={{ color: "var(--primary)" }}>.</span>
                        </h1>
                    </div>
                </div>
            )}
        </SimpleBannerStyles>
    )
}

export default SimpleBanner
